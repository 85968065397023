/**
 *  External dependencies
 */
import { Tabs, Text, Box } from '@bigcommerce/big-design';
import { useState, useEffect, Fragment } from 'react';

/**
 * Internal Dependencies
 */
import { getMarkets } from 'api/services/markets';
import TableMarkets from 'components/table-markets/table-markets';

function App() {
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);
	const [activeTab, setActiveTab] = useState('tab-1');

	const items = [
		{ ariaControls: 'content1', id: 'tab-1', title: 'Product Markers' },
		{ ariaControls: 'content2', id: 'tab-2', title: 'Waitlist' },
		{ ariaControls: 'content3', id: 'tab-3', title: 'Lottery' }
	];

	const fetchData = async () => {
		try {
			const data = await getMarkets();
			setData(data);
		} catch (error) {
			setError(true);
			console.log(error.message);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="App">
			<Box
				borderBottom="box"
				borderLeft="box"
				borderRight="box"
				shadow="floating"
				margin="large"
				padding="large"
				style={{ overflow: 'hidden' }}
			>
				{error && (
					<Text bold color="danger50">
						Something went wrong..
					</Text>
				)}

				{!error && (
					<Fragment>
						<Tabs
							aria-label="Example Tab Content"
							activeTab={activeTab}
							items={items}
							onTabClick={setActiveTab}
						/>

						<Box marginTop="large">
							{activeTab === 'tab-1' && (
								<TableMarkets data={data} title="Product Markers" onChange={() => fetchData()} />
							)}

							{activeTab === 'tab-2' && (
								<TableMarkets data={data} title="Waitlist" onChange={() => fetchData()} />
							)}

							{activeTab === 'tab-3' && (
								<TableMarkets data={data} title="Lottery" onChange={() => fetchData()} />
							)}
						</Box>
					</Fragment>
				)}
			</Box>
		</div>
	);
}

export default App;
