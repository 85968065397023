import API from './../api';

/**
 * Get all markets
 */
const getMarkets = () => (
	API.get('https://lightning.a2hosted.com/markers')
		.then(res => {
			const [keys, ...entries] = res.data;
			
			return entries.map((entry) => entry.reduce((accumulator, value, index) => ({...accumulator, [keys[index]]: value}), {}));
		})
)

/**
 * Create a new market
 */
const createMarket = (formData) => {
	return API.post('https://lightning.a2hosted.com/markers/create', formData)
		.then(res => res.data)
}

/**
 * Create a new market
 */
 const deleteMarket = (id) => {
	return API.delete(`https://lightning.a2hosted.com/markers/${id}`)
	.then(res => res.data)
}


export {
	getMarkets,
	createMarket,
	deleteMarket
}