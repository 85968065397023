/**
 * External Dependencies
 */
import { StatefulTable, Flex, FlexItem, Text, Input } from '@bigcommerce/big-design';
import { SearchIcon } from '@bigcommerce/big-design-icons';
import { Fragment, useState } from 'react';

/**
 * Internal Dependencies
 */
import TableMarketsCreate from 'components/table-markets/table-markets-create';
import TableMarketsDelete from 'components/table-markets/table-markets-delete';
import { useEffect } from 'react';

const TableMarkets = ({data, title, onChange}) => {
	const [items, setItems] = useState(data);
  const [searchValue, setSearchValue] = useState('');
	
	const onSearchChange = (event) => {
		setSearchValue(event.target.value);
	}

  const filterRows = () => {
    setItems((prevItems) => {
      if (!searchValue) return data
			
      return prevItems.filter((item) => {
				const { marker_label, product_sku, expiry } = item;
				const columnsToFilter = { marker_label, product_sku, expiry };

				return Object.values(columnsToFilter).some(value => value.toLowerCase().trim().includes(searchValue.trim()))
			});
    });
  };
	
	const handleDataChange = () => {
		if (typeof onChange === 'function') onChange();
	}

	useEffect(() => {
		filterRows();
	}, [data, searchValue])
	
	return (
		<Fragment>
			{ title && 
				<Flex alignItems="center" justifyContent="space-between" backgroundColor="secondary70" padding="medium" borderRadius="normal" marginBottom='large'>
					<FlexItem>
						<Text as="h3" bold color="white">
							{ title }
						</Text>

					</FlexItem>

					<FlexItem>
						<TableMarketsCreate title="Add Product Marker" onSubmit={() => handleDataChange()} />
					</FlexItem>
				</Flex>
			}

			<Input
          placeholder="Search"
          type="text"
					iconLeft={<SearchIcon />}
          onChange={onSearchChange}
          value={searchValue}
        />
				
			<StatefulTable
				columns={[
					{ header: 'Id', hash: 'id', render: ({ id }) => id },
					{ header: 'Sku', hash: 'product_sku', render: ({ product_sku }) => product_sku },
					{ header: 'Marker Label', hash: 'marker_label', render: ({ marker_label }) => marker_label },
					{ header: 'Expiry', hash: 'expiry', render: ({ expiry }) => expiry },
					{ header: '', hash: 'actions', render: ({id}) => <TableMarketsDelete id={id} onSubmit={() => handleDataChange()} /> }
				]}
				items={items}
				pagination
				emptyComponent={<Text style={{textAlign: 'center'}} bold marginVertical="large">No Entries Found</Text>}
			/>
		</Fragment>
	)
};

export default TableMarkets;
