/**
 * External Dependencies
 */
import { Fragment, useState } from 'react';
import { DeleteIcon } from '@bigcommerce/big-design-icons';
import { Button, Modal, Box, Text } from '@bigcommerce/big-design';

/**
 * Internal Dependencies
 */
import { deleteMarket } from 'api/services/markets';

const TableMarketsDelete = ({id, onSubmit}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [message, setMessage] = useState(undefined);

	const handleButtonClick = async () => {
		try {
			const res = await deleteMarket(id);
			setMessage(res.deleted ? 'True' : 'False');

			setIsOpen(true);
		} catch (error) {
			setMessage(error.message);
		}
	}

	const handleCloseButtonClick = () => {
		setIsOpen(false);

		if (message === 'True') onSubmit();
	}

	return (
		<Fragment>
			<Button type="button" onClick={handleButtonClick}>
				<DeleteIcon /> Delete
			</Button>

			<Modal
				actions={[
					{
						text: 'Close',
						onClick: () => handleCloseButtonClick()
					},
				]}
				closeOnClickOutside={false}
				closeOnEscKey={true}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			>
				{ message &&
					<Box paddingVertical="large">
						<Text>{ message }</Text>
					</Box>
				}
			</Modal>
		</Fragment>
	);
};

export default TableMarketsDelete;