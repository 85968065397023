/**
 * External Dependencies
 */
import { Fragment, useState } from 'react';
import { Button, Modal, Form, FormGroup, Input, Box  } from '@bigcommerce/big-design';

/**
 * Internal Dependencies
 */
import { createMarket } from 'api/services/markets';

const TableMarketsCreate = ({title, onSubmit}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [formData, setFormData] = useState({
		marker_id: '',
		marker_label: '',
		product_sku: '',
		product_id: '',
		product_name: '',
		expiry: '',
		count: '',
	})

	const handleInputChange = (event) => setFormData(prevData => (
		{...prevData, [event.target.name]: event.target.value}
	));

	const handleFormSubmit = async () =>  {
		try {
			await createMarket(formData);
		} catch (error) {
			console.log(error.message);
		}

		onSubmit();
		handleModalClose();
	};

	const handleModalClose = () => {
		setFormData({
			marker_id: '',
			marker_label: '',
			product_sku: '',
			product_id: '',
			product_name: '',
			expiry: '',
			count: '',
		});

		setIsOpen(false)
	};

	return (
		<Fragment>
			<Button type="button" onClick={() => setIsOpen(true)}>
				{' '}
				New{' '}
			</Button>

			<Modal
				actions={[
					{
						text: 'Cancel',
						variant: 'subtle',
						onClick: () => handleModalClose()
					},
					{ text: 'Create', onClick: () => handleFormSubmit(false) }
				]}
				closeOnClickOutside={false}
				closeOnEscKey={true}
				header={title}
				isOpen={isOpen}
				onClose={() => handleModalClose()}
			>
				<Box paddingVertical='large'>
					<Form fullWidth>
						<FormGroup>
							<Input
								label="Marker ID"
								onChange={handleInputChange}
								type="text"
								name="marker_id"
								value={formData.marker_id}
								required
								/>
						</FormGroup>

						<FormGroup>
							<Input
								label="Marker Label"
								onChange={handleInputChange}
								type="text"
								name="marker_label"
								value={formData.marker_label}
								required
							/>
						</FormGroup>

						<FormGroup>
							<Input
								label="Marker sku"
								onChange={handleInputChange}
								type="text"
								name="product_sku"
								value={formData.product_sku}
								required
							/>
						</FormGroup>

						<FormGroup>
							<Input
								label="Product ID"
								onChange={handleInputChange}
								type="text"
								name="product_id"
								value={formData.product_id}
								required
							/>
						</FormGroup>

						<FormGroup>
							<Input
								label="Product Name"
								onChange={handleInputChange}
								type="text"
								name="product_name"
								value={formData.product_name}
								required
							/>
						</FormGroup>

						<FormGroup>
							<Input
								label="Expiry"
								onChange={handleInputChange}
								type="text"
								name="expiry"
								value={formData.expiry}
								required
							/>
						</FormGroup>

						<FormGroup>
							<Input
								label="Count"
								name="count"
								onChange={handleInputChange}
								type="text"
								value={formData.count}
								required
							/>
						</FormGroup>
					</Form>
				</Box>
			</Modal>
		</Fragment>
	);
};

export default TableMarketsCreate;
